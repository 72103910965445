// Angular
import {
    Directive,
    Inject,
    OnInit,
    PLATFORM_ID,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { isPlatformServer } from '@angular/common';

/**
 * Directive to the element that we want while rendering on the server 
 * @example Add `*appShellRender` inorder to allow element 
 * @export
 * @class AppShellRenderDirective
 * @implements {OnInit}
 */
@Directive({
    selector: '[appShellRender]',
})
export class AppShellRenderDirective implements OnInit {

    /**
     * Creates an instance of AppShellRenderDirective.
     * @param {Object} platformId
     * @param {TemplateRef<any>} templateRef
     * @param {ViewContainerRef} viewContainer
     * @memberof AppShellRenderDirective
     */
    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef
    ) { }

    /**
     * On Init
     * @memberof AppShellRenderDirective
     */
    ngOnInit() {
        if (isPlatformServer(this.platformId)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
