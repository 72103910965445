// Angular
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFmtYearMonth'
})
export class DateFmtYearMonthPipe implements PipeTransform {

  /**
   * Transform example 200303 ==> 2003/03
   * @param {(string | number)} value
   * @returns {string}
   * @memberof DateFmtYearMonthPipe
   */
  transform(value: string | number): string {
    if (value) {
      const strValue = value.toString();
      if (strValue.length !== 6) {
        return value as string; // Return the original value if it doesn't match the format
      }
      const year = strValue.slice(0, 4);
      const month = strValue.slice(4, 6);
      return `${year}/${month}`;
    }
    return '';
  }

}
