<ng-container *transloco="let t">
  @if (!hasAccepted) {
  <div class="popup">
    @if (languageChange$ | async; as _lang) {
    <!-- begin:: Cookie Banner -->
    <div>
      <span transloco="paragraph_use_of_cookie_message"></span>
      <a href="/{{_lang}}/documents/{{t('label_url_use_of_cookies')}}" target="_blank" class="d-block link-underline">
        <span transloco="label_more_details"></span>
      </a>
      <div class="my-2 d-flex justify-content-between">
        <span>
          <i class="bi bi-check-square-fill me-2 text-primary"></i>
          <label transloco="label_essential"></label>
        </span>
        <span>
          <i class="bi me-2"
            [ngClass]="{'bi-check-square-fill text-primary': siteAnalyticsCookie, 'bi-x-square-fill text-danger': !siteAnalyticsCookie}"></i>
          <label transloco="label_site_analytics"></label>
        </span>
        <span>
          <i class="bi me-2"
            [ngClass]="{'bi-check-square-fill text-primary': googleAdsCookie, 'bi-x-square-fill text-danger': !googleAdsCookie}"></i>
          <label transloco="label_google_ads"></label>
        </span>
      </div>
      <div class="buttons">
        <button type="button" class="btn btn-secondary btn-sm me-2" (click)="openCookieSetting()"
          transloco="label_cookie_settings"></button>
        <button type="button" (click)="onAcceptCookie()" class="btn btn-sm btn-primary"
          transloco="label_got_it"></button>
      </div>
    </div>
    <!-- end:: Cookie Banner -->

    <!-- being:: Cookie Setting Model -->
    <div class="modal fade" tabindex="-1" aria-modal="true" role="dialog"
      [ngStyle]="{'display': isModalOpen ? 'block' : 'none'}"
      [ngClass]="{ 'show cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing': isModalOpen }">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">

          <!-- begin:: Modal Header -->
          <div class="modal-header mh-60px px-3">
            <h3 class="modal-title" transloco="label_cookie_settings"></h3>

            <!--begin::Close-->
            <div (click)="onOpenPopup()" class="btn btn-icon btn-sm btn-active-light-primary ms-2" aria-label="Close">
              <i class="ki-duotone ki-cross fs-1"><span class="path1"></span><span class="path2"></span></i>
            </div>
            <!--end::Close-->
          </div>
          <!-- end:: Modal Header -->

          <!-- begin:: Modal Body -->
          <div class="modal-body p-0">
            <ol class="list-group list-group-flush">
              <li class="list-group-item d-flex align-items-center justify-content-between">
                <div class="me-2">
                  <div class="fw-bold" transloco="label_essential"></div>
                  <p transloco="paragraph_essential_cookies"></p>
                </div>
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="strictCookieCheckDisabled" checked
                    disabled>
                  <label class="form-check-label" for="strictCookieCheckDisabled"></label>
                </div>
              </li>
              <li class="list-group-item d-flex align-items-center justify-content-between">
                <div class="me-2">
                  <div class="fw-bold" transloco="label_site_analytics"></div>
                  <p transloco="paragraph_analytics_cookies"></p>
                </div>
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" [(ngModel)]="siteAnalyticsCookie" role="switch"
                    id="flexSwitchPerformance" checked>
                  <label class="form-check-label" for="flexSwitchPerformance"></label>
                </div>
              </li>
              <li class="list-group-item d-flex align-items-center justify-content-between">
                <div class="me-2">
                  <div class="fw-bold" transloco="label_google_ads"></div>
                  <p transloco="paragraph_google_ads_cookies"></p>
                </div>
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" [(ngModel)]="googleAdsCookie" role="switch"
                    id="flexSwitchTarget" checked>
                  <label class="form-check-label" for="flexSwitchTarget"></label>
                </div>
              </li>
            </ol>
          </div>
          <!-- end:: Modal Body -->

          <!-- begin:: Action Buttons -->
          <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-sm" (click)="onAcceptCookie()"
              transloco="label_accept_all"></button>
            <button type="button" class="btn btn-primary btn-sm" (click)="onSaveAndClose()"
              transloco="label_save_and_close"></button>
          </div>
          <!-- end:: Action Buttons -->

        </div>
      </div>
    </div>
    <!-- end:: Cookie Setting Model -->
    }
  </div>
  }@else {
  <div (click)="onOpenPopup()" id="cookie-badge" tabindex="0" role="alert">
    <div id="cookie-badgeimage">
      <svg id="cookie-badgesvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320.28 320.28">
        <g id="cs_layer_2" data-name="cs_layer_2">
          <g id="cs_layer_1" data-name="cs_layer_1">
            <path class="cookies-logo"
              d="M160.14,0A160.14,160.14,0,1,0,320.28,160.14,160.14,160.14,0,0,0,160.14,0Zm0,301.49A141.35,141.35,0,1,1,301.49,160.14,141.35,141.35,0,0,1,160.14,301.49Z">
            </path>
            <circle class="cookies-logo" cx="98.09" cy="106.52" r="35.11"></circle>
            <circle class="cookies-logo" cx="88.27" cy="200.63" r="14.09"></circle>
            <circle class="cookies-logo" cx="151.17" cy="251.06" r="22.63"></circle>
            <circle class="cookies-logo" cx="238.42" cy="180.9" r="30.49"></circle>
            <circle class="cookies-logo" cx="206.65" cy="86.27" r="18.53"></circle>
          </g>
        </g>
      </svg>
    </div>
  </div>
  }
</ng-container>