// Angular
import { isPlatformBrowser } from '@angular/common';
import {
    AfterContentInit,
    Directive,
    ElementRef,
    HostListener,
    Inject,
    Input,
    OnInit,
    PLATFORM_ID,
    Renderer2,
} from '@angular/core';

/**
 * Directive to lazy load image and add fallack image on image error load
 * @export
 * @class ImageLoaderDirective
 * @implements {OnInit}
 * @implements {AfterContentInit}
 */
@Directive({
    selector: 'img[imageLoader]',
})
export class ImageLoaderDirective implements OnInit, AfterContentInit {

    // INPUTS
    @Input()
    public src!: string;
    @Input()
    public loaderSrc: string = 'assets/media/brand-logos/no-logo.webp';
    @Input()
    public errorSrc: string = 'assets/media/brand-logos/no-logo.webp';
    @Input()
    public lazyLoad: boolean = true;

    // Private
    private alreadyTriedLoading: boolean = false;
    private alreadyTriedError: boolean = false;

    /**
     * Creates an instance of ImageLoaderDirective.
     * @param {ElementRef<HTMLImageElement>} el
     * @param {Renderer2} renderer
     * @param {Object} platformId
     * @memberof ImageLoaderDirective
     */
    constructor(
        private el: ElementRef<HTMLImageElement>,
        private renderer: Renderer2,
        @Inject(PLATFORM_ID) private platformId: Object
    ) { }

    /**
     * On Init
     * @memberof ImageLoaderDirective
     */
    ngOnInit(): void {
        this.renderer.setAttribute(this.el.nativeElement, 'src', this.src);

        if (this.lazyLoad) {
            this.renderer.setAttribute(this.el.nativeElement, 'loading', 'lazy');
        }
    }

    /**
     * After Content Init
     * @memberof ImageLoaderDirective
     */
    ngAfterContentInit(): void {
        if (this.shouldDisplayLoader()) {
            this.renderer.setAttribute(this.el.nativeElement, 'src', this.loaderSrc);
        } else {
            this.renderer.setAttribute(this.el.nativeElement, 'src', this.src);
        }
    }

    @HostListener('load')
    public onLoad(): void {
        if (!this.alreadyTriedLoading) {
            this.renderer.setAttribute(this.el.nativeElement, 'src', this.src);
        }
        this.alreadyTriedLoading = true;
    }

    @HostListener('error')
    public onError(): void {
        if (!this.alreadyTriedError) {
            this.renderer.setAttribute(this.el.nativeElement, 'src', this.errorSrc);
        }
        this.alreadyTriedError = true;
    }

    private shouldDisplayLoader(): boolean {
        return (
            isPlatformBrowser(this.platformId) && !this.el.nativeElement.complete
        );
    }
}