// Angular
import { isPlatformBrowser } from '@angular/common';
import { Directive, HostListener, Inject, Input, PLATFORM_ID } from '@angular/core';
// Environment
import { environment } from '@environment';

/**
 * Directive to the track Google analytics event i.e select_item
 * @export
 * @class GaSelectItemDirective
 */
@Directive({
    selector: '[gaSelectItem]'
})
export class GaSelectItemDirective {

    // Input
    @Input() gaSelectItem: any;

    /**
     * Creates an instance of GaSelectItemDirective.
     * @param {Object} platformId
     * @memberof GaSelectItemDirective
     */
    constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

    /**
     * Click Event
     * @memberof GaSelectItemDirective
     */
    @HostListener('click') onClick() {
        try {
            if (environment.googleAnalytics.isEnabled && isPlatformBrowser(this.platformId)) {
                gtag('event', 'select_item', {
                    items: [this.gaSelectItem],
                });
            }
        } catch (error) {
            console.log('select-item-ga4', error);
        }
    }
}
