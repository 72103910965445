// Angular
import { Pipe, PipeTransform } from '@angular/core';
// Transloco
import { TranslocoService } from '@jsverse/transloco';

@Pipe({
  name: 'companyRating'
})
export class CompanyRatingPipe implements PipeTransform {

  /**
   * Creates an instance of CompanyRatingPipe.
   * @param {TranslocoService} translocoService
   * @memberof CompanyRatingPipe
   */
  constructor(
    private readonly translocoService: TranslocoService,
  ) { }

  /**
   * Transform
   * @param value: any
   */
  transform(rating: number, onlyRateing?: boolean): any {
    if (rating && rating>0) {
      return onlyRateing ? `${rating}%` : this.translocoService.translate('dynamic_recommends_this_company', { value: rating });
    }
    return this.translocoService.translate('label_no_review');
  }
}
