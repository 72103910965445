// Angular
import { Pipe, PipeTransform } from "@angular/core";
// Transloco
import { TranslocoService } from "@jsverse/transloco";
// Pipe
import { MakeMePrettyPipe } from "./make-me-pretty.pipe";

@Pipe({
  name: "EngineGearboxUrl"
})
export class EngineGearboxUrlPipe implements PipeTransform {

  /**
   * Creates an instance of EngineGearboxUrlPipe.
   * @param {TranslocoService} translocoService
   * @param {MakeMePrettyPipe} makeMePretty
   * @memberof EngineGearboxUrlPipe
   */
  constructor(
    private readonly translocoService: TranslocoService,
    private readonly makeMePretty: MakeMePrettyPipe,
  ) { }

  /**
   * Return Returns Engine / Gearbox URL
   * @param {*} code
   * @param {string} [type='E' || 'G']
   * @returns {string}
   * @memberof EngineGearboxUrlPipe
   */
  transform(code: any, type: string = 'E' || 'G'): string {
    const link = {
      lang: this.translocoService.getActiveLang(),
      search: this.makeMePretty.transform(this.translocoService.translate('label_search')),
      type: this.makeMePretty.transform(this.translocoService.translate('label_type')),
      code: encodeURIComponent(code)
    };
    switch (type) {
      case 'E': // Engine Code
        const labelEngine = this.makeMePretty.transform(this.translocoService.translate('label_engine'));
        return `/${link.lang}/pb/${link.search}/${link.type}/s6/${labelEngine}?query=${link.code}`;

      case 'G': // Gearbox Code
        const labelGearbox = this.makeMePretty.transform(this.translocoService.translate('label_gear_box__drive_axle__middle_axle'));
        return `/${link.lang}/pb/${link.search}/${link.type}/s7/${labelGearbox}?query=${link.code}`;
    }
    return `/${link.lang}`
  }
}