// Angular
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mileToKm'
})
export class MileToKmPipe implements PipeTransform {

  /**
   * Transform Mile into Km
   * @param {number} miles
   * @returns {number}
   * @memberof MileToKmPipe
   */
  transform(miles: number): number {
    return miles ? miles * 10 : 0;
  }
}
